import { ArrowRightNext } from '@icons/Svg/ArrowIcon/ArrowRightNext';
import style from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import AdvertisingNote from "@shared/components/banners/AdvertisingNote";

const BannerRealEstate = ({type}) => {
  const { t, lang } = useTranslation('ads');
  const link = {
    en: `https://real-estate.iworld.com/en`,
    ru: `https://real-estate.iworld.com/ru`
  }

    const VBanner = () => {
     return (
       <a className={`${style.banner}`} href={link[lang]}>
         <div className={style.container}>
           <AdvertisingNote text={t('note')}/>
           <div className={style.wrap}>
             <div className={`${style.title} ${style.title}`}>
               {t('realEstate.title')}
             </div>
             <ul className={`${style.text}`}>
               <li>{t('realEstate.text1')}</li>
               <li>{t('realEstate.text2')}</li>
             </ul>
             <span className={style.getConsultation}>
                {t('search')}
               <ArrowRightNext width="20" height="12" fill="#000000"/>
              </span>
           </div>
         </div>
       </a>
     )
    }

    const HBanner = () => {
      return (
        <a className={`${style.bannerHor}`} href={link[lang]} target="_blank">
          <div className={style.wrapper}>
            <div className={style.wrapHor}>
              <div className={`${style.title} ${style.titleHor}`}>
                {t('realEstate.title')}
              </div>
              <ul className={`${style.text} ${style.textHor}`}>
                <li>{t('realEstate.text1')}</li>
                <li>{t('realEstate.text2')}</li>
              </ul>
              <span className={`${style.getConsultation} ${style.getConsultationHor}`}>
              {t('search')}
                <ArrowRightNext width="20" height="12" fill="#000000"/>
            </span>
            </div>
          </div>
      </a>)
    }

  return (
    type ? <HBanner/> : <VBanner/>
  );
};


export default BannerRealEstate;
