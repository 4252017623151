import * as React from 'react';

const ArrowRightNext = React.memo(({ className, size, height = '12', width = '7', fill = '#3570C9' }) => {
  const w = size || width;
  const h = size || height;
  return (
    <svg className={className} width={w} height={h} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.816701 0L7 6L0.816701 12L0 11.2075L5.3666 6L0 0.792491L0.816701 0Z"
        fill={fill}
      />
    </svg>
  );
});

export { ArrowRightNext };
