import style from './style.module.scss';

const AdvertisingNote = ({ text }) => {
  return (
    <div className={style.note}>
      <div className={style.noteInner}>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default AdvertisingNote;
